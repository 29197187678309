<script setup>
import Input from '@components/Input.vue'
import { ref, computed } from 'vue'

const props = defineProps({
  id: String,
  name: String,
  label: String,
  value: [String, Number],
  modelValue: [String, Number],
  required: Boolean,
  errors: Array
})

const emit = defineEmits([
  'update:date',
])
const [d, m, y] = props.modelValue != null ? props.modelValue.split('-') : [null,null,null]
const day = ref(d ?? '')
const month = ref(m ?? '')
const year = ref(y ?? '')

const errorClass = "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
const validClass = "text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-stone-600"

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

const date = computed(() => {
  return `${day.value}-${month.value}-${year.value}`
})
</script>

<template>
  <div>
    <label :for="props.name" class="block text-sm font-semibold text-gray-900 leading-6">
      {{props.label}} <span v-if="props.required" class="text-red-500">*</span>
    </label>
    <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-3 mt-2">
      <div>
        <input
          v-model="day"
          @blur="$emit('update:date', date)"
          type="text"
          :name="`${props.name}-day`"
          :id="`${props.id}-day`"
          :required="props.required"
          :class="[ props?.errors?.length ? errorClass : validClass]"
          class="block w-full rounded-md border-0 py-1.5 pr-10 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
          placeholder="DD"
          :aria-invalid="props?.errors?.length ? errors[0] : false"
          :aria-describedby="props?.errors?.length ? props.name+'-error' : null"
        >
        <small class="text-xs text-gray-600">
          Day
        </small>
      </div>
      <div>
        <select
            :aria-describedby="props?.errors?.length ? props.name+'-error' : null"
            :aria-invalid="props?.errors?.length ? errors[0] : false"
            :class="[ props?.errors?.length ? errorClass : validClass]"
            :id="`${props.id}-month`"
            :name="`${props.name}-month`"
            :required="props.required"
            :value="month"
            @blur="$emit('update:date', date)"
            class="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-stone-600 sm:text-sm sm:leading-6"
            v-model="month"
            >
            <option disabled value="">
                Month
            </option>
            <option
              v-for="(value,index) in months"
              :value="('0' + (index+1)).slice(-2)">
              {{ value }}
            </option>
        </select>
        <small class="text-xs text-gray-600">
          Month
        </small>
      </div>
      <div>
        <input
          v-model="year"
          @blur="$emit('update:date', date)"
          type="text"
          :name="`${props.name}-year`"
          :id="`${props.id}-year`"
          :required="props.required"
          :class="[ props?.errors?.length ? errorClass : validClass]"
          class="block w-full rounded-md border-0 py-1.5 pr-10 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
          placeholder="YYYY"
          :aria-invalid="props?.errors?.length ? errors[0] : false"
          :aria-describedby="props?.errors?.length ? props.name+'-error' : null"
        >
        <small class="text-xs text-gray-600">
          Year
        </small>
      </div>
    </div>
    <p v-for="error in props?.errors" class="mt-2 text-sm text-red-600" :id="props.name+'-error'">
      {{ error }}
    </p>
  </div>
</template>
